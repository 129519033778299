const config = {
  query: {
    status: 0,
    title: null,
    place: null,
    type: null,
  },
  // 表格最大高度
  maxHeight: document.body.clientHeight - 340,
  // 表格配置
  columnList: Object.freeze([
    { label: 'ID', prop: 'id' },
    { label: '图片', slotName: 'tburl' },
    { label: '备注', prop: 'title' },
    { label: '显示时间', slotName: 'time' },
    { label: '显示状态', slotName: 'status' },
    { label: '位置', prop: 'position' },
    {
      label: '操作',
      isButton: true,
      btnList: [
        { label: '编辑', type: 'primary', eventName: 'addNewSpread' },
        {
          label: '关闭',
          type: 'primary',
          eventName: 'handleSpread',
          showName: '_status',
          showStatusList: ['正在显示'],
        },
        // {
        //   label: '开启',
        //   type: 'primary',
        //   eventName: 'handleSpread',
        //   showName: '_status',
        //   showStatusList: ['关闭'],
        // },
        {
          label: '删除',
          type: 'danger',
          eventName: 'handleSpread',
          showName: '_status',
          showStatusList: ['已显示', '关闭', '未显示', '即将结束'],
        },
      ],
    },
  ]),
  statusSelect: Object.freeze([
    {
      label: '正在显示',
      value: '正在显示',
      color: '#1890ff',
    },
    {
      label: '已显示',
      value: '已结束',
      color: '#F85255',
    },
    {
      label: '未显示',
      value: '未显示',
      color: '#E1BC2C',
    },
    {
      label: '关闭',
      value: '关闭',
      color: '#979797',
    },
    {
      label: '即将结束',
      value: '即将结束',
      color: '#979797',
    },
  ]),
  statusList: Object.freeze([
    {
      id: 0,
      label: '全部状态',
    },
    {
      id: 1,
      label: '正在显示',
    },
    {
      id: 2,
      label: '已结束',
    },
    {
      id: 3,
      label: '未显示',
    },
    {
      id: 4,
      label: '已关闭',
    },
  ]),
  // 按钮列表
  buttonList: Object.freeze([
    {
      label: '新增广告',
      type: 'primary',
      icon: 'el-icon-plus',
      eventName: 'handleCreate',
    },
  ]),
}

export default config
