<template>
  <div class="operate-list-container">
    <SearchForm
      :label-width="70"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @addNewSpread="addNewSpread"
      @handleCreate="handleCreate"
      @handleCurrentChange="handleCurrentChange"
      @handleSpread="handleSpread"
    >
      <!-- 图片 -->
      <template #tburl="{ row }">
        <el-image
          :preview-src-list="[row.pic_arr[0].tburl]"
          :src="row.pic_arr[0].tburl"
        />
      </template>
      <!-- 时间 -->
      <template #time="{ row }">
        <div>
          <p>{{ row.begin_time }}</p>
          <p>{{ row.end_time }}</p>
        </div>
      </template>
      <!-- 状态 -->
      <template #status="{ row }">
        <template v-for="item in statusSelect">
          <span
            v-if="row._status == item.label"
            :key="item.value"
            :style="{ color: item.color }"
          >
            {{ item.value }}
          </span>
        </template>
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import { getAdPageList, closeAd, openAd, delAppletAd } from '@/api/operate'
  import config from './config'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  export default {
    components: {
      SearchForm,
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      searchColumn() {
        return [
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            clearable: false,
            selectList: this.statusList,
          },
          { label: '关键字', prop: 'keywords' },
        ]
      },
    },
    created() {
      this.query.place = this.$route.query.place
      this.getTableData()
    },
    methods: {
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getAdPageList(params).then((res) => {
          this.loading = false
          this.list = res.list || []
          this.pageInfo.count = res.total
        })
      },
      // 新增广告
      handleCreate() {
        this.$router.push({
          path: `${this.$route.path}/add`,
          query: {
            place: this.query.place,
            title: this.$route.query.title,
          },
        })
      },
      // 编辑
      addNewSpread(val) {
        this.$router.push({
          path: `${this.$route.path}/edit`,
          query: {
            place: this.query.place,
            title: this.$route.query.title,
            id: val.id,
          },
        })
      },
      // 关闭/开启/删除
      handleSpread(val, index, buttonName) {
        const params = {
          id: val.id,
        }
        this.$confirm(`此操作将${buttonName}当前广告, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            if (buttonName == '关闭') {
              res = await closeAd(params)
            } else if (buttonName == '开启') {
              res = await openAd(params)
            } else if (buttonName == '删除') {
              res = await delAppletAd(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
    },
  }
</script>

<style></style>
