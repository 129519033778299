var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "operate-list-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-width": 70,
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          addNewSpread: _vm.addNewSpread,
          handleCreate: _vm.handleCreate,
          handleCurrentChange: _vm.handleCurrentChange,
          handleSpread: _vm.handleSpread,
        },
        scopedSlots: _vm._u([
          {
            key: "tburl",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-image", {
                  attrs: {
                    "preview-src-list": [row.pic_arr[0].tburl],
                    src: row.pic_arr[0].tburl,
                  },
                }),
              ]
            },
          },
          {
            key: "time",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", [
                  _c("p", [_vm._v(_vm._s(row.begin_time))]),
                  _c("p", [_vm._v(_vm._s(row.end_time))]),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._l(_vm.statusSelect, function (item) {
                  return [
                    row._status == item.label
                      ? _c(
                          "span",
                          { key: item.value, style: { color: item.color } },
                          [_vm._v(" " + _vm._s(item.value) + " ")]
                        )
                      : _vm._e(),
                  ]
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }